

nav{
    background: #040C18; ; 
    position: sticky;
    top: 0px;
    border-radius: 10px;
    z-index: 1000;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    color: #fff;
   
}
.nav__container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.2rem 0;
   
}


.nav-link {
  display: flex;
  gap: 2rem;
 
  
}
.nav-link a{
    color: #fff;
}


.nav__btn {
    display: flex;
    gap: 1rem;
}

.nav__login-btn,
.login-btn{
    padding:10px 22px ;
    font-weight: 500;
    background: transparent;
    text-align: center;
    border-radius: 6px;
    
}

.login-btn:hover{
    transition: var(--transition);
    border-bottom: solid 2px white;
    transition: var(--transition);
    transform: translateY(-2px);
}
.launchapp-btn{

}
.ham__cro{
    display: none;
    margin-top: 0.5rem;
    font-size: 1.5rem;
    color: #fff;
}
.nav__menu-container{
    display: none;
}



@media screen and (max-width: 1020px) {
    .nav__link{
        display: none;
    }
    .ham__cro{
        display: flex;
    }
    
    .nav__menu-container{ 
        display: flex;       
        
        flex-direction: column;
        gap: 1rem;
        text-align: center;
        height: fit-content;
        width: 100%;
        z-index: -50;
        background: rgba(49, 10, 88, 0.365);
        border-radius: 10px 0 ;
    }
    .nav__menu-link{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin: 1rem 0;
    }

    .nav__menu-btn{
        display: none; 
    }
 
}

@media screen and (max-width: 790px) {
   
   .nav__menu-link{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        
    }
    .nav__menu-btn{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }
    .login-btn,
    .launchapp-btn{
        display: none; 
    }

 
   
    
}





