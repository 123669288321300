*{
  margin:0;
  padding:0;
  border:0;
  outline:0;
  list-style:none;
  text-decoration: none;
  box-sizing: border-box;
}
:root{
      /* GENERAL CONTAINER VARIABLES */
      --container-width-lg: 85%;
      --container-width-md: 90%;
      /* BORDER RADIUS VARIABLES */
      --border-radius-1: 1.2rem;
      --border-radius-2: 0.8rem;
      --border-radius-3: 0.5rem;
      --border-radius-4: 50%;
      /* GENERAL TRANSITION VARIABLE */
      --transition: all 500ms ease;
  }
  html{
    scroll-behavior: smooth;
}

body{
  font-family: 'Manrope', sans-serif;
  line-height:1.6;
  font-size: 0.9rem;
  background:   #040C18; 
  color: white;
}


/* ALL CONTAINER CLASS */
.container{
  width: var(--container-width-lg);
  margin-inline: auto;
  max-width: 1920px;  
}

/* ALL SECTION STYLES */
section{
  padding:20rem 0;
 
}

a{
  color: #191818;
}
.btn{
  padding:10px 22px ;
  font-weight: 500;
  text-align: center;
  background: #0C37AA;
  border-radius: 6px;
}
.btn-trans{
  padding:10px 22px ;
  font-weight: 500;
  text-align: center;
  border-radius: 6px;
}

.btn:hover{
  transition: var(--transition);
 
  background: linear-gradient(90deg, #1346CF -1.49%, #2FAAFB 101.74%);
}
.btn-trans:hover{
  transition: var(--transition);

  }

